import React, { useEffect, useRef, useState } from 'react';
import { Button, TextInput } from '@nucleos/core-ui';

import AuthenticationStore from '../Stores/Authentication';
import achieveDxpLoginArt from '../Assets/achieve-dxp-login-art.svg';
import DataStore from '../Stores/DataStore';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GenericErrorDetectorForMutations } from '../Middleware/Api';
import LoadingComponent from '../Components/Widgets/LoadingComponent';
import { Alert, Box, Container, Grid, Tooltip, Typography } from '@mui/material';
import Data from '../Middleware/Data';
import CaptureUserImage from './CaptureUserImage';
import { getExtensionInfo } from '../Lib/extension';
import debounce from 'lodash.debounce';
import { Icon } from '../Components/Shared/Icon';
import { AppContext } from '../Contexts/AppContext';
import toast from 'react-hot-toast';

export default function Login () {
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const forceReload = Boolean(searchParams.get('force-reload'));
  const redirectTo = searchParams.get('redirectTo');

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipContainerRef = useRef();
  const [state, setState] = useState({
    username: '',
    password: ''
  });

  const deviceSerialNumber = window.nucleosDeviceInfo && window.nucleosDeviceInfo.serialNumber;
  const usernameLabel = window.nucleosConfig.usernameLabel || 'Username';
  const usernameLabelLongText = window.nucleosConfig.usernameLabelLongText;
  const tenantId = window.nucleosConfig.tenantId || '';
  const [isLearnerMode, setIsLearnerMode] = useState(null);
  const isHelpIconEnabled =
    (window.nucleosConfig.showHelpIconOnUsernameInput &&
      isLearnerMode === true) ||
    false;
  const [errors, setErrors] = useState({
    username: false,
    password: false,
    error: ''
  });
  const currentVideoRef = useRef();
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);
  const [showImageCapture, setShowImageCapture] = useState(false);
  const loginMutation = useMutation(
    () =>
      AuthenticationStore.attemptLogin(
        state.username,
        state.password,
        null,
        window.nucleosDeviceInfo
      ).then(GenericErrorDetectorForMutations),
    {
      onError: (error) => {
        if (error.response) {
          if (typeof error.response.error === 'string') {
            setErrors({
              error: error.response.error,
              username: false,
              password: false
            });
          }
          if (typeof error.response.message === 'string') {
            setErrors({
              error: error.response.message,
              username: false,
              password: false
            });
          }
        }
      }
    }
  );
  const tutorialsQuery = useQuery(['TUTORIALS', tenantId], () =>
    Data.getTutorials(tenantId)
  );
  const tutorialVideoQuery = useQuery(
    ['TUTORIAL_VIDEO', activeVideoIndex, tenantId],
    () => Data.getTutorial(tutorialsQuery.data[activeVideoIndex].uid, tenantId),
    {
      enabled: activeVideoIndex !== null && tutorialsQuery.status === 'success'
    }
  );

  const deviceDetailsQuery = useQuery(
    ['DeviceDetails', deviceSerialNumber],
    () => Data.getDeviceDetails({ serialNumber: deviceSerialNumber }),
    {
      enabled: (
        !!window.nucleosConfig.userDeviceLockDownEnabled &&
        !!window.nucleosConfig.userFlaggingEnabled &&
        !!deviceSerialNumber
      )
    }
  );

  useEffect(() => {
    if (forceReload) {
      window.location.replace(location.pathname);
    } else {
      if (localStorage.getItem('logoutMessage')) {
        toast.error(localStorage.getItem('logoutMessage'));
      }

      localStorage.setItem('logoutMessage', '');
    }

    queryClient.clear();
    getExtensionInfo()
      .then(() => setIsLearnerMode(true))
      .catch(() => setIsLearnerMode(false));
  }, []);

  useEffect(() => {
    const onDocumentClick = (event) => {
      if (
        tooltipContainerRef.current &&
        !tooltipContainerRef.current.contains(event.target)
      ) {
        setIsTooltipOpen(false);
      }
    };
    document.addEventListener('mousedown', onDocumentClick);
    return () => document.removeEventListener('mousedown', onDocumentClick);
  }, []);

  useEffect(() => {
    const isGSuiteEnabled = window.nucleosConfig.gsuite;
    localStorage.setItem('title', '');
    localStorage.setItem('appURL', '');
    localStorage.setItem('show', false);
    DataStore.setLogout(false);
    const { search, pathname } = history.location;
    const authenticated = AuthenticationStore.isAuthenticated;
    const searchParams = new URLSearchParams(search);
    const redirectTo = searchParams.get('redirectTo');

    // Not Authenticated
    if (!authenticated) {
      // website root "/"" goes to SSO login flow
      if (pathname === '/') {
        // SSO Login
        localStorage.setItem('loginPath', '/');
        if (!isGSuiteEnabled) {
          window.location.assign(`${getLoginHost()}/login`);
        } else {
          window.location.assign(redirectTo || '/login');
        }
        return;
      }
    }

    // Authenticated
    if (authenticated) {
      // root & /login goes to /home
      if (['/', '/login'].includes(pathname)) {
        setShowImageCapture(false);
        history.push(redirectTo || '/home');
      } else if (search.length > 0) {
        try {
          const code = search.split('=')[1].split('&')[0];
          AuthenticationStore.openidLogin(code);
        } catch (e) {
          console.error(e);
        }

        // history.push({
        //   pathname: redirectTo ? redirectTo : undefined,
        //   search: "",
        // });

        window.location.replace(redirectTo || '/home');
      }
    }
  }, [AuthenticationStore.isAuthenticated]);

  useEffect(() => {
    if (!currentVideoRef.current) { return; }
    currentVideoRef.current.load();
  }, [activeVideoIndex]);

  useEffect(() => {
    if (
      AuthenticationStore.isValidUser &&
      isLearnerMode &&
      window.nucleosConfig.captureFaceEnabled
    ) {
      setShowImageCapture(true);
    }
  }, [AuthenticationStore.isValidUser]);

  const getLoginHost = () => {
    return (
      window.location.protocol +
      '//' +
      (window.location.host.startsWith('localhost')
        ? 'localhost:8082'
        : 'sp.' + window.location.host)
    );
  };

  const videoContainerHeight = currentVideoRef.current
    ? activeVideoIndex !== null
      ? currentVideoRef.current.getBoundingClientRect().height
      : 0
    : 0;

  const onLoginClick = debounce(() => {
    if (loginMutation.isLoading) {
      return;
    }

    if (!state.username && !state.password) {
      return setErrors({
        username: true,
        password: true,
        error: `Please enter ${usernameLabel} and Password.`
      });
    }
    if (!state.username) {
      return setErrors({
        username: true,
        password: false,
        error: `Please enter ${usernameLabel}.`
      });
    }
    if (!state.password) {
      return setErrors({
        username: false,
        password: true,
        error: 'Please enter password.'
      });
    }
    setErrors({});
    loginMutation.mutate();
  }, 100);

  const pathname = history.location.pathname;

  const GuidelinesUI = (
    <div className="p-4 relative" ref={tooltipContainerRef} style={{ padding: '20px 30px', width: '350px' }}>
      <Typography className="mb-2" fontWeight={800} fontSize='14px'>
        SF ID Number Guidelines:
      </Typography>
      <Box component="ul" sx={{
        listStyleType: 'disc !important'
      }}>
        <Typography component="li" variant='disabled' fontSize='13px'>
          Look for a number on your bracelet, that start with &ldquo;S&rdquo; followed by 6
          numbers. eg. S123456
        </Typography>
        <Typography component="li" variant='disabled' fontSize='13px'>
          Enter only the numeric part, for eg &ldquo;123456&rdquo;
        </Typography>
        <Typography component="li" variant='disabled' fontSize='13px'>
          Contact the system admin if you still unable to find this number
        </Typography>
      </Box>
      <span id="login-guideline-arrow"></span>
    </div>
  );

  if (forceReload) { return null; }
  if (isLearnerMode === null) { return <LoadingComponent />; }

  const usernameLabelText =
    isLearnerMode === true
      ? usernameLabelLongText || usernameLabel
      : usernameLabel;

  const onLoginSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onLoginClick();
  };

  const renderLoginForm = () => (
    <Grid container spacing={6}>
      <Grid item xs={5} sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
          <Typography sx={{ fontWeight: 700, fontSize: '32px', lineHeight: '43px' }} color='primary' >
            Welcome to
          </Typography>

          <Box sx={{ display: 'flex' }}>
            <AppContext.Consumer>
              {
                (appDataConfig) => (
                  <img alt="Nucleos Logo" src={appDataConfig.logoURL} style={{ marginTop: '10px', maxHeight: '48px' }} />
                )
              }
            </AppContext.Consumer>
          </Box>

          <Typography
            variant='disabled'
            style={{
              fontSize: '16px',
              fontWeight: '400',
              marginTop: '50px',
              marginBottom: '20px'
            }}
          >
            Kindly enter your credentials to access your account
          </Typography>

          {errors.error
            ? (
              <Box sx={{ width: '100%' }}>
                <Alert severity='error'>{errors.error}</Alert>
              </Box>
            )
            : null}
          <form onSubmit={onLoginSubmit}>
            <Typography style={{ fontSize: '14px' }}>
              {usernameLabelText}
            </Typography>
            <div id="creds-input" className="relative mt-2">
              <TextInput
                className="mb-4"
                label={`Enter ${usernameLabelText}`}
                placeholder={`Enter ${usernameLabelText}`}
                onChange={(username) => setState({ ...state, username: username.trim() })}
                error={errors.username}
                disableHelperText
              />
              {isHelpIconEnabled && (
                <Tooltip
                  title={GuidelinesUI}
                  open={isTooltipOpen}
                  placement="top"
                  PopperProps={{ disablePortal: true }}
                  classes={{ tooltip: 'bg-white', popper: 'opacity-100' }}
                >
                  <div
                    className="absolute right-5 top-2.5"
                    onClick={() => setIsTooltipOpen(true)}
                  >
                    {HelpIcon}
                  </div>
                </Tooltip>
              )}
            </div>
            <Typography style={{ fontSize: '14px' }}>Password</Typography>
            <div id="creds-input" className='mt-2'>
              <TextInput
                className="mb-4"
                label="Password"
                type="password"
                placeholder="Enter Password"
                onChange={(password) => setState({ ...state, password })}
                error={errors.password}
                disableHelperText
              />
            </div>
            <Button
              color='primary'
              variant="contained"
              style={{ marginTop: '20px' }}
              type="submit"
              loading={loginMutation.isLoading}
              disabled={loginMutation.isLoading}
              fullWidth
            >
              {
                isLearnerMode && window.nucleosConfig.captureFaceEnabled
                  ? 'Continue'
                  : 'Login'
              }
            </Button>
            {isLearnerMode === false && (
              <Box className="mt-4" sx={{ display: 'flex', justifyContent: 'center' }}>
                <Link to="/forgot-password">
                  <Typography variant="disabled" sx={{ fontSize: '15px', fontWeight: '400' }}>Forgot Username/Password?</Typography>
                </Link>
              </Box>
            )}
          </form>
        </Box>
      </Grid>
      <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <img
          id="login-art"
          alt="Login art"
          src={achieveDxpLoginArt}
        />
      </Grid>
    </Grid>
  );

  if (AuthenticationStore.isAuthenticated && isLearnerMode === false) {
    if (pathname === '/' || pathname === '/login') {
      if (DataStore.isCurrentUserFormsAdmin()) { history.push('/forms/admin'); } else { history.push(redirectTo || '/home'); }
    }
    return null;
  } else if (pathname === '/') {
    return <LoadingComponent />;
  } else {
    return (
      <Box
        className="nucleos-core"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        }}
      >
        <Box sx={{ flex: 1, display: 'flex', mx: 4 }}>
          <Container
            maxWidth="lg"
            sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 10, pb: isLearnerMode ? 5 : 10 }}
          >
            {
              showImageCapture
                ? (
                  <CaptureUserImage
                    username={state.username}
                    password={state.password}
                    onCancelClick={({ reload } = {}) => {
                      if (reload) {
                        window.location.reload();
                      } else {
                        setShowImageCapture(false);
                      }
                    }}
                  />
                )
                : (
                  <Box sx={{ flex: 1 }}>
                    {renderLoginForm()}
                  </Box>
                )
            }
          </Container>
        </Box>

        <Box sx={{ width: '100%' }}>
          {
            isLearnerMode === true && tutorialsQuery.status === 'success' && (
              <div className="bg-lightblue-light">
                <div className="container mx-auto" style={{ maxWidth: 1000 }}>
                  <section
                    className="mx-auto pt-12 pb-12"
                    style={{ maxWidth: '80vw' }}
                  >
                    <div className="flex justify-between">
                      <Typography variant="h1" color="primary">
                        {activeVideoIndex !== null
                          ? tutorialsQuery.data[activeVideoIndex].label
                          : 'Video Guide: How to use Nucleos'}
                      </Typography>
                      <div
                        className={[
                          'w-4 h-4 cursor-pointer',
                          activeVideoIndex !== null ? 'block' : 'hidden'
                        ].join(' ')}
                        onClick={() => setActiveVideoIndex(null)}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.595736 0.829938L0.705236 0.703937C0.895718 0.513411 1.14825 0.397569 1.41691 0.377475C1.68558 0.35738 1.95253 0.434367 2.16924 0.594437L2.29524 0.703937L12.0002 10.4074L21.7052 0.702437C21.8091 0.595039 21.9332 0.509393 22.0705 0.450499C22.2078 0.391604 22.3554 0.36064 22.5048 0.359413C22.6542 0.358186 22.8023 0.386721 22.9406 0.443352C23.0788 0.499983 23.2043 0.583577 23.3099 0.689255C23.4155 0.794934 23.499 0.920581 23.5555 1.05887C23.612 1.19715 23.6404 1.3453 23.639 1.49468C23.6376 1.64405 23.6065 1.79166 23.5475 1.92889C23.4885 2.06611 23.4027 2.19021 23.2952 2.29394L13.5917 11.9989L23.2967 21.7039C23.487 21.8946 23.6026 22.1472 23.6224 22.4159C23.6422 22.6846 23.565 22.9514 23.4047 23.1679L23.2952 23.2939C23.1048 23.4845 22.8522 23.6003 22.5836 23.6204C22.3149 23.6405 22.0479 23.5635 21.8312 23.4034L21.7052 23.2939L12.0002 13.5904L2.29524 23.2954C2.08296 23.5003 1.79873 23.6135 1.50376 23.6108C1.20879 23.6081 0.926679 23.4897 0.718192 23.281C0.509706 23.0723 0.391523 22.7901 0.3891 22.4951C0.386677 22.2001 0.500206 21.916 0.705236 21.7039L10.4087 11.9989L0.703736 2.29394C0.513434 2.10327 0.397869 1.85064 0.37805 1.58198C0.358231 1.31332 0.435474 1.04647 0.595736 0.829938Z"
                            fill="#333333"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className={[
                        'my-4 mb-8 transition-all ease-in-out duration-300 overflow-hidden'
                      ].join(' ')}
                      style={{ height: videoContainerHeight }}
                    >
                      <video
                        controls
                        ref={currentVideoRef}
                        className="h-96 w-full rounded-md"
                        autoPlay
                      >
                        {activeVideoIndex !== null &&
                        tutorialVideoQuery.status === 'success' && (
                          <source src={tutorialVideoQuery.data.url} />
                        )}
                      </video>
                    </div>
                    <div className={'grid gap-12 grid-cols-3 mobile-grid-col-2'}>
                      {(tutorialsQuery.data || []).map((video, index) => {
                        return (
                          <div className="inline-grid" key={index}>
                            <div
                              className="flex flex-col justify-between"
                              onClick={() => setActiveVideoIndex(index)}
                            >
                              <Typography
                                color="primary"
                                variant="h6"
                                className="mb-2"
                              >
                                {video.label}
                              </Typography>
                              <div className="relative">
                                <video
                                  id="video-thumbnail"
                                  className="rounded-md"
                                  poster={video.thumbnail}
                                />
                                <Icon
                                  icon="Play"
                                  className="h-16 w-16 absolute top-1/2 left-1/2 rounded-full"
                                  style={{
                                    transform: 'translateX(-50%) translateY(-50%)',
                                    color: 'white'
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                </div>
              </div>
            )
          }
        </Box>

        {
          !deviceDetailsQuery.isLoading &&
          deviceSerialNumber
            ? (
              <Box sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                mr: 3,
                mt: 3,
                borderRadius: 1
              }}>
                <Alert
                  severity='warning'
                  color={deviceDetailsQuery.data ? 'error' : 'info'}
                  icon={deviceDetailsQuery.data ? '' : false}
                  sx={(theme) => ({
                    border: 1,
                    borderWidth: '1px !important',
                    borderColor: `${deviceDetailsQuery.data ? theme.palette.error.light : `${theme.palette.info.light}70`} !important`,
                    alignItems: 'center'
                  })}
                >
                  {
                    deviceDetailsQuery.data
                      ? (
                        <Typography variant='body2' fontWeight={700}>
                          {deviceDetailsQuery.data.User.firstName} {deviceDetailsQuery.data.User.lastName}
                        </Typography>
                      )
                      : null
                  }

                  <Typography variant={deviceDetailsQuery.data ? 'caption' : 'body2'} fontWeight={600}>
                    {
                      `Device serial #${String(deviceSerialNumber).slice(String(deviceSerialNumber).length - 4)}`
                    }
                  </Typography>
                </Alert>
              </Box>
            )
            : null
        }
      </Box>
    );
  }
}

const HelpIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_2521_1114)">
      <mask
        id="mask0_2521_1114"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="22"
      >
        <path d="M0 1.90735e-06H16V16H0V1.90735e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2521_1114)">
        <path
          d="M15.5414 7.99992C15.5414 12.1649 12.1651 15.5413 8.00008 15.5413C3.83508 15.5413 0.45874 12.1649 0.45874 7.99992C0.45874 3.83495 3.83508 0.458573 8.00008 0.458573C12.1651 0.458573 15.5414 3.83495 15.5414 7.99992Z"
          stroke="#333333"
          strokeMiterlimit="10"
        />
        <path
          d="M5.87354 6.34537C5.87354 5.14062 6.87304 4.16934 8.08807 4.21546C9.19279 4.25743 10.0929 5.15759 10.1349 6.26234C10.1638 7.02168 9.79519 7.69684 9.2206 8.09646C8.44891 8.63318 8.005 9.52777 8.005 10.4678"
          stroke="#333333"
          strokeMiterlimit="10"
        />
        <path
          d="M8.46875 12.1255H7.53125V11.188H8.46875V12.1255Z"
          fill="#333333"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2521_1114">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
